import { useDraggable, Translate } from "@dnd-kit/core";
import type { List } from "@openqlabs/drm-db";
import { ListIcon } from "lucide-react";
import Link from "next/link";
import { useEffect, useState } from "react";
import { SidebarMenuButton, SidebarMenuItem } from "~/components/ui/sidebar";

const SidebarList = ({ list }: { list: List }) => {
  const [draggedLocation, setDraggedLocation] = useState<Translate | null>(
    null
  );
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: list.id,
      data: {
        id: list.id,
        type: "list",
      },
    });
  useEffect(() => {
    if (isDragging) {
      setDraggedLocation(transform);
    }
    if (!isDragging) {
      setTimeout(() => {
        setDraggedLocation(null);
      }, 250);
    }
  }, [transform, isDragging]);
  const style = draggedLocation
    ? {
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",

        transform: `translate3d(${draggedLocation.x}px, ${draggedLocation.y}px, 0)`,
      }
    : undefined;

  return (
    <SidebarMenuItem
      style={style}
      ref={setNodeRef}
      key={list.name}
      {...listeners}
      {...attributes}
    >
      <SidebarMenuButton className="my-0.5" variant="ghost" asChild>
        <Link className="flex items-center gap-4 " href={`/lists/${list.id}`}>
          <>
            <ListIcon className="w-4 h-4" /> {list.name}
          </>
        </Link>
      </SidebarMenuButton>
    </SidebarMenuItem>
  );
};

export default SidebarList;
