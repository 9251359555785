import type { List } from "@openqlabs/drm-db";
import { FolderIcon } from "lucide-react";
import type { ListFolder } from "node_modules/@openqlabs/drm-db/generated/.prisma/client";
import {
  SidebarGroup,
  SidebarGroupContent,
  SidebarMenuItem,
} from "~/components/ui/sidebar";
import SidebarList from "./SidebarList";
import {
  CollapsibleTrigger,
  Collapsible,
  CollapsibleContent,
} from "~/components/ui/collapsible";
import CreateNestedLists from "./CreateNestedLists";
import { useDraggable, useDroppable } from "@dnd-kit/core";

import { ContextMenu, ContextMenuTrigger } from "~/components/ui/context-menu";
import Link from "next/link";

const SidebarFolder = ({
  folder,
}: {
  folder: ListFolder & { lists?: List[]; childFolders?: ListFolder[] };
}) => {
  const { isOver, setNodeRef, active } = useDroppable({
    id: folder.id,
  });
  const getChildFolderIds = (folder: ListFolder): string[] => {
    const folderWithChildFolders = folder as ListFolder & {
      childFolders?: ListFolder[];
    };
    const childFolderIds =
      folderWithChildFolders?.childFolders?.map(
        (childFolder) => childFolder.id
      ) ?? [];
    if (folderWithChildFolders.childFolders) {
      return [
        ...childFolderIds,
        ...folderWithChildFolders.childFolders.flatMap(getChildFolderIds),
      ];
    }
    return childFolderIds;
  };
  const childFolderIds = getChildFolderIds(folder);
  const activeIsParent = (
    active?.data.current as {
      prohibitedFolders: string[];
    }
  )?.prohibitedFolders?.some((id) => id === folder.id);

  const isDroppable = isOver && !activeIsParent;

  const className = isDroppable ? "text-green-500" : "";

  const {
    attributes,
    listeners,
    setNodeRef: setNodeRefDraggable,
    transform,
    isDragging,
  } = useDraggable({
    id: folder.id,
    data: {
      id: folder.id,
      type: "folder",
      prohibitedFolders: childFolderIds,
    },
  });
  const style = transform
    ? {
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  return (
    <SidebarMenuItem className="p-0 ">
      <ContextMenu>
        <ContextMenuTrigger>
          <Collapsible defaultOpen className={`group/collapsible h-fit p-0`}>
            <SidebarGroup ref={setNodeRef} className=" h-fit">
              <div style={style} className="flex gap-4 items-center">
                <CollapsibleTrigger className={className}>
                  <FolderIcon className="w-4 h-4" />
                </CollapsibleTrigger>
                <div
                  ref={setNodeRefDraggable}
                  {...listeners}
                  {...attributes}
                  key={folder.name}
                >
                  <Link href={`/folders/${folder.id}`}> {folder.name}</Link>
                </div>
              </div>

              <CollapsibleContent>
                <SidebarGroupContent className="px-2">
                  <CreateNestedLists
                    folderName={folder.name}
                    folderId={folder.id}
                  >
                    {folder.lists?.map((list) => {
                      return <SidebarList key={list.id} list={list} />;
                    })}
                    {folder.childFolders?.map((childFolder) => {
                      return (
                        <SidebarFolder
                          key={childFolder.id}
                          folder={childFolder}
                        />
                      );
                    })}
                  </CreateNestedLists>
                </SidebarGroupContent>
              </CollapsibleContent>
            </SidebarGroup>
          </Collapsible>
        </ContextMenuTrigger>
      </ContextMenu>
    </SidebarMenuItem>
  );
};

export default SidebarFolder;
