import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { type List as CompleteList } from "~/server/api/model/list";
import { api, type RouterOutputs } from "~/server/trpc/react";
import { useTeamAccount } from "./TeamAccountProvider";
import { useLists } from "./ListsProvider";
import useId from "~/hooks/useId";

type RefetchFunc = () => Promise<void>;
type ContactDisconnector = (
  githubContactId: string,
  contactId: string,
  type: "user" | "repo"
) => void;

type List = RouterOutputs["list"]["listById"];

const ListContext = createContext<List | null>(null);
const ListRefetchContext = createContext<RefetchFunc | null>(null);
const ListUpdateContext = createContext<ReturnType<
  typeof api.list.listUpdate.useMutation
> | null>(null);

const ListDisconnectContactContext = createContext<ContactDisconnector | null>(
  null
);

export function useList() {
  const list = useContext(ListContext) as CompleteList;
  return list;
}

export function useListRefetch() {
  return useContext(ListRefetchContext) as RefetchFunc;
}

export function useListUpdate() {
  return useContext(ListUpdateContext) as ReturnType<
    typeof api.list.listUpdate.useMutation
  >;
}

export function useListDeleteContact() {
  return useContext(ListDisconnectContactContext) as ContactDisconnector;
}

export function ListProvider({
  teamAccountId,
  children,
  initialId,
}: {
  initialId: string;
  teamAccountId?: string;
  children: React.ReactNode;
}) {
  const id = useId("listId", initialId) as string;

  const [data, setData] = useState<RouterOutputs["list"]["listById"] | null>(
    null
  );
  const { handleRefetch: listsRefetch } = useLists();
  const { activeTeamAccount } = useTeamAccount();
  const activeTeamAccountId = teamAccountId ?? activeTeamAccount?.id;

  const query = api.list.listById.useQuery(
    {
      listId: id as string,
      teamAccountId: activeTeamAccountId,
    },
    { refetchOnWindowFocus: false }
  );

  const { refetch } = query;

  const refetchOnSuccess = {
    onSuccess: async () => {
      await refetch();
      listsRefetch();
    },
  };

  const update = api.list.listUpdate.useMutation(refetchOnSuccess);
  const deleteContact = api.list.deleteContact.useMutation(refetchOnSuccess);

  const refetchList = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const disconnectContact = useCallback(
    (githubContactId: string, contactId: string, type: string) => {
      deleteContact.mutate({
        type,
        listId: id,
        teamAccountId: activeTeamAccountId,
        githubContactId,
        contactId,
      });
    },
    [deleteContact, activeTeamAccountId, data]
  );

  useEffect(() => {
    if (query.isSuccess) {
      setData(query.data);
    }
  }, [query]);

  if (!data || !update || !refetchList || !disconnectContact) {
    return null;
  }

  return (
    <ListContext.Provider value={data}>
      <ListUpdateContext.Provider value={update}>
        <ListRefetchContext.Provider value={refetchList}>
          <ListDisconnectContactContext.Provider value={disconnectContact}>
            {children}
          </ListDisconnectContactContext.Provider>
        </ListRefetchContext.Provider>
      </ListUpdateContext.Provider>
    </ListContext.Provider>
  );
}
