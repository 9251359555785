import * as React from "react";
import { Check, ChevronsUpDown } from "lucide-react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu";
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "~/components/ui/sidebar";
import Link from "next/link";
import Image from "next/image";
import { useTeamAccount } from "~/providers/TeamAccountProvider";

export function VersionSwitcher() {
  const { activeTeamAccount, teamAccounts, setActiveTeamAccount } =
    useTeamAccount();

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              variant="ghost"
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
            >
              <div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-sidebar-primary text-sidebar-primary-foreground">
                <Link href="/">
                  <Image
                    width="20"
                    height="20"
                    alt="openq-logo"
                    src="/openq-logo-white.png"
                  />
                </Link>
              </div>
              <div className="flex flex-col gap-0.5 leading-none">
                <span className="font-semibold">OpenQ</span>
                <span className="">{activeTeamAccount.name}</span>
              </div>
              <ChevronsUpDown className="ml-auto" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-[--radix-dropdown-menu-trigger-width]"
            align="start"
          >
            {teamAccounts.map((teamAccount) => (
              <DropdownMenuItem
                key={teamAccount.id}
                onSelect={() => setActiveTeamAccount(teamAccount)}
              >
                {teamAccount.name}
                {teamAccount.id === activeTeamAccount.id && (
                  <Check className="ml-auto" />
                )}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
