import { useParams } from "next/navigation";

const useId = (key: string, propId?: string) => {
  const params = useParams() as Record<string, string>;
  const id = params[key];
  if (typeof id === "string") {
    return id;
  }
  if (propId) {
    return propId;
  }
};
export default useId;
